import algosdk from 'algosdk';
import chunk from 'lodash/chunk.js';
import { generateBoxReference, genLease } from './util.js';

export default async function makeClaimTxn(algod, contract, appId, addr, aids) {
  if (aids.length > 12) {
    throw new Error("too many asset IDs");
  }

  const suggestedParams = await algod.getTransactionParams().do();

  const binaryAddress = algosdk.decodeAddress(addr).publicKey;
  const txns = [];

  for(const aid of aids) {
    txns.push(algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      assetIndex: aid,
      from: addr,
      to: addr,
      amount: 0,
      suggestedParams,
    }));
  }

  if (aids.length > 2) {
    for(const _ of chunk(aids, 6)) {
      txns.push(algosdk.makeApplicationNoOpTxnFromObject({
        from: addr,
        appIndex: appId,
        appArgs: [contract.getMethodByName('nullun').getSelector()],
        suggestedParams,
        lease: genLease(),
        boxes: [generateBoxReference(appId, binaryAddress)],
      }));
    }
  }

  for(const foreignAssets of chunk(aids, 6)) {
    const appSuggParams = {
      ...suggestedParams,
      flatFee: true,
      fee: 1000 + 1000 * foreignAssets.length,
    }
    txns.push(algosdk.makeApplicationNoOpTxnFromObject({
      from: addr,
      appIndex: appId,
      appArgs: [contract.getMethodByName('claim_nfts').getSelector()],
      foreignAssets,
      suggestedParams: appSuggParams, 
      lease: genLease(),
      boxes: [generateBoxReference(appId, binaryAddress)],
    }));
  }

  algosdk.assignGroupID(txns);

  return txns;
}
