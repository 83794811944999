import { useState, useEffect, useCallback } from 'react';
import './App.css';
import algosdk from 'algosdk';
import client from './lib/configs/client.json';
import player from './lib/configs/player.json';
import chunk from 'lodash/chunk.js';

import { errorExplanations, getAvailablePrizes, makeUserRevealTxn, makeClaimTxn, getClaimableNFTs, getUserScheduledReveal, parseContractError, lootboxes, prizes, getCurrentRound, waitForRoundAfter } from './lib/index.js';

function short(str) {
  return typeof str === 'string' ? str.slice(0, 6)+'...'+str.slice(-6) : str;
}

const { uri, port, token } = client;

const algodClient = new algosdk.Algodv2(token, uri, port);

const { addr, sk } = algosdk.mnemonicToSecretKey(player.key);

async function startWatchReveal(algod, round, address, doneCallback, roundCallback) {
  let currentRound = await getCurrentRound(algod);
  roundCallback(currentRound);
  while(await getUserScheduledReveal(algod, address)) {
    const nextRound = currentRound;
    console.log('awaiting', nextRound);
    const { "last-round": lastRound } = await waitForRoundAfter(algod, nextRound);
    currentRound = lastRound;
    roundCallback(currentRound);
  }
  console.log("revealed");
  doneCallback();
}

async function executeTxns(txns) {
  const signed = txns.map(txn => algosdk.signTransaction(txn, sk));
  const txID = txns[txns.length - 1].txID();
  await algodClient.sendRawTransaction(signed.map(({blob}) => blob)).do();
  await algosdk.waitForConfirmation(algodClient, txID, 8);
  return txID;
}

function getLootboxLevelFromName(name) {
  switch(name) {
    case 'ELBX': return 1;
    case 'ELBXv2': return 2;
    case 'ELBXv3': return 3;
    default: throw new Error('Unknown lootbox asset name: '+name);
  }
}

function App() {
  const [claimable, setClaimable] = useState([]);
  const [scheduledReveal, setScheduledReveal] = useState(null);
  const [availablePrizes, setAvailablePrizes] = useState([]);
  const [claimedPrizes, setClaimedPrizes] = useState([]);
  const [lootboxBalance, setLootboxBalance] = useState({})
  const [lastTXID, setLastTXID] = useState();
  const [currentRound, setCurrentRound] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState();
  const [parsedError, setParsedError] = useState();
  const [errorExplanation, setErrorExplanation] = useState();

  const sendClaim = useCallback(() => {
    (async() => {
      setSending(true);
      setLastTXID();
      setError();
      setParsedError();
      setErrorExplanation();
      try {
        if (claimable.length > 12) {
          alert('You can only collect 12 prizes at a time. Collecting the first 12.');
        }
        if (claimable.length === 0) {
          alert('No prizes to claim!');
          return
        }
        const aids = chunk(claimable, 12)[0];
        const txns = await makeClaimTxn(algodClient, addr, aids);
        const txId = await executeTxns(txns);
        setLastTXID(txId);
        refreshState();
      } catch(e) {
        console.error(e);
        setError(e.message);
        const parsed = parseContractError(e.message);
        setParsedError(parsed);
        const expl = errorExplanations[parsed];
        if (expl) {
          setErrorExplanation(expl);
        }
      } finally {
        setSending(false);
      }
    })()
  }, [claimable]);

  const sendReveal = useCallback((aid) => {
    (async() => {
      setSending(true);
      setLastTXID();
      setError();
      setParsedError();
      setErrorExplanation();
      try {
        const txns = await makeUserRevealTxn(algodClient, addr, aid);
        const txId = await executeTxns(txns);
        setLastTXID(txId);
        refreshState();
      } catch(e) {
        console.error(e);
        setError(e.message);
        const parsed = parseContractError(e.message);
        setParsedError(parsed);
        const expl = errorExplanations[parsed];
        if (expl) {
          setErrorExplanation(expl);
        }
      } finally {
        setSending(false);
      }
    })()
  }, []);

  const refreshState = useCallback(() => {
    (async() => {
      // request everything in parallel
      const [accInfo, availablePrizes, claimableNFTs, scheduledReveal] = await Promise.all([
        algodClient.accountInformation(addr).do(),
        getAvailablePrizes(algodClient),
        getClaimableNFTs(algodClient, addr),
        getUserScheduledReveal(algodClient, addr),
      ]);
      setAvailablePrizes(availablePrizes);
      setClaimable(claimableNFTs);
      setScheduledReveal(scheduledReveal);

      const lootboxAssetIDs = Object.values(lootboxes);
      for(const { "asset-id": aid, amount } of accInfo.assets) {
        if (lootboxAssetIDs.includes(aid)) {
          setLootboxBalance(balance => ({ ...balance, [aid]: amount }))
        }
        if (prizes.includes(aid)) {
          setClaimedPrizes(prizes => ([...prizes, aid]));
        }
      }
    })()
  }, []);

  useEffect(() => {
    refreshState();
  }, []);

  // singleton reveal watcher
  const [isRevealing, setIsRevealing] = useState(false);
  useEffect(() => {
    if (scheduledReveal && !isRevealing) {
      console.log('start reveal watcher', { scheduledReveal, isRevealing });
      setIsRevealing(true);
      startWatchReveal(algodClient, scheduledReveal.round, addr, refreshState, setCurrentRound);
    } else if (!scheduledReveal && isRevealing) {
      console.log('stop reveal watcher', { scheduledReveal, isRevealing });
      setIsRevealing(false);
    } else {
      console.log('reveal watcher effect noop', { scheduledReveal, isRevealing });
    }
  }, [scheduledReveal, addr, isRevealing]);

  const hasLootbox = Object.entries(lootboxes).some(([name, aid]) => lootboxBalance[aid]);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          <a href={`https://app.dappflow.org/explorer/account/${addr}`}>{ short(addr) }</a>
        </p>
        {
          scheduledReveal ? <>
            <p>Revealing on round {scheduledReveal.round+2} with odds multiplier {scheduledReveal.multiplier}x<br />Current round: {currentRound}</p>
            <hr />
          </> : null
        }
        { claimable.length ? <>
          Claimable prizes: {claimable.length} <br />
          Asset IDs: {[...claimable].reverse().join(' ') /* Users would expect to see last revealed prize at the start of the list*/}
          <button onClick={() => sendClaim(claimable)}>Claim</button>
          <hr />
        </> : null }
        { !hasLootbox ? <>No lootboxes to reveal</> : null }
        { Object.entries(lootboxes).map(([name, aid]) => {
          const level = getLootboxLevelFromName(name);
          if (!lootboxBalance[aid]) return null;
          return <p>
            L{level}: balance {lootboxBalance[aid]}
            <button onClick={() => sendReveal(aid)}>send 1</button>
          </p>
        }) }
          <hr />
        { sending ? <h2>Sending</h2> : null }
        { lastTXID ? <p>Last TX ID: 
          <a target="_blank" href={`https://app.dappflow.org/explorer/transaction/${lastTXID}`}>{ short(lastTXID) }</a>
        </p> : null }
            { error ? <>
              Parsed error: <u>{parsedError}</u>
              <hr />
              { errorExplanation ? <><strong>{errorExplanation}</strong><hr /></> : null }
              Full error: <br />
              {error}
            </> : null }
        { claimedPrizes.length ? <>
          <p>Claimed prizes: {claimedPrizes.join(' ')}</p><hr />
        </> : null }
        { availablePrizes.length ? <>
          <p>Available prizes: {availablePrizes.length} <br/>Asset IDs: {availablePrizes.join(' ')}</p>
        </> : null }
      </header>
    </div>
  );
}

export default App;
