import algosdk from 'algosdk';
import aids from './configs/aids.json';
import deploy from './configs/deploy.json';
import _contract from './configs/contract.json';
import _lootboxes from './configs/lootboxes.json';
import _makeUserRevealTxn from './user-reveal.js';
import _makeClaimTxn from './claim.js';
import { errorExplanations as _errorExplanations } from './errors.js';
import {
  getClaimableNFTs as _getClaimableNFTs,
  getUserScheduledReveal as _getUserScheduledReveal,
  getCurrentRound as _getCurrentRound,
  waitForRoundAfter as _waitForRoundAfter,
  uint32ArrayToNumeric,
  getBox,
} from './util.js';

const contract = new algosdk.ABIContract(_contract);

const { app_id: appId } = deploy;

export const errorExplanations = _errorExplanations;

export const getCurrentRound = _getCurrentRound;

export const waitForRoundAfter = _waitForRoundAfter;

export const getClaimableNFTs = (algod, address) => _getClaimableNFTs(algod, appId, address);

export const getUserScheduledReveal = (algod, address) => _getUserScheduledReveal(algod, appId, address);

export const makeUserRevealTxn = async (algod, addr, lootboxAssetID) =>
   _makeUserRevealTxn(algod, contract, appId, addr, lootboxAssetID);

export const makeClaimTxn = (algod, addr, lootboxAssetIDs) =>
  _makeClaimTxn(algod, contract, appId, addr, lootboxAssetIDs);

const contractErrorRegex = /logic eval error.*assert.*byte.*\/\/ "([^"]+)"/;

export const lootboxes = _lootboxes;

const lootboxAssetIDs = Object.values(lootboxes);

export const prizes = Object.values(aids).filter(aid => !lootboxAssetIDs.includes(aid));

export function parseContractError(message) {
  const match = contractErrorRegex.exec(message);
  return match && match[1];
}

export async function getAvailablePrizes(algod) {
  const values = await getBox(algod, appId, Buffer.from('nfts'));
  return uint32ArrayToNumeric(values).filter(Boolean);
}
