export const errorExplanations = {
  "ERR INVALID GROUP SIZE": "Explanation: validation of your lootbox payment failed.",
  "ERR 1ST TRANSACTION AMOUNT IS NOT 1": "Explanation: validation of your lootbox payment failed.",
  "ERR 1ST TRANSACTION UNACCEPTABLE ASSET": "Explanation: validation of your lootbox payment failed.",
  "ERR 1ST TRANSACTION INVALID RECEIVER": "Explanation: validation of your lootbox payment failed.",
  "ERR 1ST TRANSACTION IS NOT ASSET TRANSFER": "Explanation: validation of your lootbox payment failed.",

  "ERR REVEAL IN PROGRESS ALREADY": "Explanation: A reveal is in progress already for your account. Please wait up to 13 seconds for the reveal to be executed before trying to reveal another lootbox.",

  "ERR NO FOREIGN ASSET IDs": "Explanation: Your claim transaction was not formed correctly. The contract was expecting foreign assets to be set, but none were.",

  "ERR NO VALUES": "Explanation: You tried to claim NFTs from the contract, but your account had no NFTs to be claimed at this time. Check your wallet in case a previous claim attempt succeeded.",

  "ERR VALUE NOT FOUND": "Explanation: One of the prize NFTs you tried to claim are not allocated to your account. Check your wallet in case a previous claim attempt succeeded or try again."
};
