import algosdk from 'algosdk';
import { addressToRevealBoxName, generateBoxReference } from './util.js';

/*
 * I tried to symlink these files to ../js-clients and hit an infuriating bug where
 * the Transaction objects were returns as instanceof Transaction from the makeTxn functions
 * but were received as object literals on the react side. I suspect this was due to a 
 * different project boundary where one was ESM/module (js-clients) and the other, not
 */

export default async function makeUserRevealTxn(algod, contract, appId, addr, aid) {
  const appAddr = algosdk.getApplicationAddress(appId);

  const suggestedParams = await algod.getTransactionParams().do();

  const revealBoxName = addressToRevealBoxName(addr);

  const txns = [
    algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      assetIndex: aid,
      from: addr,
      to: appAddr,
      amount: 1,
      suggestedParams,
    }),
    algosdk.makeApplicationNoOpTxnFromObject({
      from: addr,
      appIndex: appId,
      appArgs: [
        contract.getMethodByName('user_reveal').getSelector(),
      ],
      accounts: [],
      boxes: [generateBoxReference(appId, revealBoxName)],
      suggestedParams,
    }),
  ];

  algosdk.assignGroupID(txns);

  return txns;
}
