import algosdk from 'algosdk';
import chunk from 'lodash/chunk.js';

const { decodeAddress, decodeUint64, encodeObj } = algosdk;

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const die = (...args) => {
  console.error(...args);
  process.exit(1);
}

export async function getScheduledReveals(algod, appId) {
  const { boxes } = await algod.getApplicationBoxes(appId).do();
  return boxes.filter(({ name }) => name.length === 33 && name[0] === 82) // R = 82
    .map(({ name }) => name);
}

export async function getClaimableNFTs(algod, appId, addr) {
  const { publicKey: name } = decodeAddress(addr);
  try {
    const box = await algod.getApplicationBoxByName(appId, name).do();
    return chunk(box.value, 4).map(byteArr => decodeUint64(Buffer.from(byteArr)));
  } catch(e) {
    if (e.message.includes('box not found')) {
      return [];
    }
    console.error(e);
    throw e;
  }
}

export function genLease() {
    const b = Buffer.alloc(32);
    for(let i=0; i<32; i++) {
        b[i] = Math.floor(Math.random() * 256);
    }
    return new Uint8Array(b);
}

export function generateBoxReference(appIndex, name) {
  if (typeof name === 'string') {
    name = encodeObj(name).slice(1);
  }
  return {
    appIndex,
    name,
  };
}

export function generateBoxReferences(num, appIndex, name='nfts') {
  if (typeof name === 'string') {
    name = encodeObj(name).slice(1);
  }
  return new Array(num).fill(1).map(_ => ({
    appIndex,
    name,
  }));
}

export async function getBox(algod, appId, name) {
  const data = await algod.getApplicationBoxByName(appId, name).do();
  return data.value;
}

export function addressToRevealBoxName(addr) {
  const binaryAddress = algosdk.decodeAddress(addr).publicKey;
  return new Uint8Array(
    Buffer.concat([
      Buffer.from('R'),
      binaryAddress,
    ])
  );
}

export async function getUserScheduledReveal(algod, appId, addr) {
  const revealBoxName = addressToRevealBoxName(addr);
  try {
    const value = await getBox(algod, appId, revealBoxName);
    const multiplier = parseInt(Buffer.from(value.slice(0, 8)).toString('hex'), 16)
    const round = parseInt(Buffer.from(value.slice(8)).toString('hex'), 16);
    return { round, multiplier };
  } catch(e) {
    if (e.message.includes('box not found')) {
      return null;
    }
    throw e;
  }
}

export const snakeCaseToCamel = (str) => str.replace(/_./g, s => s[1].toUpperCase())

export const getCurrentRound = async algod => (await algod.status().do())['last-round'];

export const waitForRoundAfter = async (algod, round) => algod.statusAfterBlock(round).do();

export const uint32ArrayToNumeric = (buffer) => chunk(buffer, 4).map(byteArr => Number(decodeUint64(Buffer.from(byteArr))));

